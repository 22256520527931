<template>
  <b-modal id="modal-toko" size="lg" title="Informasi Toko">
    <b-row>
      <b-col v-for="(form, index) in forms" :key="index" cols="12" md="12" class="mb-2 custom__form--input">
        <template v-if="form.model === 'renewal_end_date'">
          <label>Tanggal Berakhir</label>
          <BFormDatepicker v-model="formPayload[form.model]" class="custom__input" size="lg" locale="in" style="
                      background-color: #eff1f5;
                      border: none;
                      min-height: 53px;
                      display: grid;
                      place-items: center;
                    " disabled />
        </template>
        <template v-else-if="form.model === 'manual_payment_date'">
          <label>Tanggal Pembayaran Manual</label>
          <BFormDatepicker v-model="formPayload[form.model]" class="custom__input" size="lg" locale="in" style="
                      background-color: #eff1f5;
                      border: none;
                      min-height: 53px;
                      display: grid;
                      place-items: center;
                    " />
        </template>
        <template v-else-if="form.model === 'renewal_status'">
          <label>Status Akun</label>
          <v-select v-model="formPayload[form.model]" label="name" :options="optionStatus"
            :reduce="(optionStatus) => optionStatus.value" placeholder="Status" />
        </template>
        <template v-else>
          <BaseInput v-model="formPayload[form.model]" :class="form.class" :type="form.type" :name="form.name"
            :required="form.required" :disabled="form.disabled" :placeholder="form.placeholder" :label="form.label"
            :class-label="form.classLabel" :message="messages.map((e) => e[form.model]).join(', ')" />
        </template>
      </b-col>
      <b-col cols="12 mb-1">
        <hr class="my-1" />
      </b-col>
      <b-col cols="12">
        <h5 v-if="formPayload.users" class="text-black-2 fw-bold-600 size16 mb-0">
          Daftar Users ({{ formPayload.users.length }})
        </h5>
        <div v-for="(user, index) in formPayload.users" :key="index">
          <b-card class="py-1 mt-1 border-12 d-flex flex-row justify-content-between" style="background-color: #eff1f5"
            no-body>
            <b-col cols="12" sm="6">
              <div class="text-dark fw-bold-500 size14 mb-0">
                {{ user.name }}
              </div>
              <div class="text-dark fw-bold-500 size14 mb-0">
                {{ user.email }}
              </div>
              <div class="text-dark fw-bold-500 size14 mb-0">
                {{ user.phone }}
              </div>
            </b-col>
            <b-col cols="12" sm="6" class="text-center">
              <div class="size14 mb-0">Terakhir login</div>
              <div class="text-dark fw-bold-500 size14 mb-0">
                {{ user.last_login }}
              </div>
            </b-col>
          </b-card>
        </div>
      </b-col>
      <b-col cols="12" class="mb-2">
        <h5 v-if="formPayload.sellers" class="text-black-2 fw-bold-600 size16 mb-0">
          Daftar Sales ({{ formPayload.sellers.length }})
        </h5>
        <b-card v-for="(seller, index) in formPayload.sellers" :key="index"
          class="p-1 mt-1 border-12 d-flex flex-row justify-content-between" style="background-color: #eff1f5" no-body>
          <div class="d-flex align-items-center">
            <div class="py-1">
              <div class="text-dark fw-bold-500 size14 mb-0">
                Nama: {{ seller.name || "-" }}
              </div>
              <div class="text-dark fw-bold-500 size14 mb-0">
                No. HP: {{ seller.phone_number || "-" }}
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <h2 class="text-dark fw-bold-700 mb-3 size24">Riwayat</h2>
        <app-timeline class="mb-2">
          <h6 v-if="historyDataList.length == 0" class="text-primary">
            Belum ada riwayat
          </h6>
          <app-timeline-item v-for="(history, index) in historyDataList" :key="index">
            <template>
              <div class="d-flex flex-sm-row flex-column flex-wrap mb-1 mb-sm-0">
                <h6 class="mb-0 size14" style="margin-right: 5px" v-text="history.title" />
                <h6 class="timeline-item-time text-nowrap text-dark text-darken-5 fw-bold-500 size14"
                  v-text="`oleh ${history.operator_name}`" />
              </div>
              <div v-for="(
                          property, keyProperty, indexProperty
                        ) in history.properties" :key="indexProperty">
                <div v-if="
                  keyProperty !== 'tax_id_photo' &&
                  keyProperty !== 'id_card_photo'
                ">
                  <div class="d-flex align-items-center mb-1">
                    <h6 class="text-capitalize text-dark fw-bold-600 mb-0 size14">
                      {{ keyProperty.replaceAll("_", " ") }}:
                    </h6>
                    <h5 class="text-dark fw-bold-600 mb-0" style="margin-left: 5px" v-text="property.from || '-'" />
                    <feather-icon v-if="property.to || property.from" size="18" class="text-dark fw-bold-600"
                      style="margin-left: 5px; margin-right: 5px" icon="ArrowRightIcon" />
                    <h5 class="text-dark fw-bold-600 mb-0" v-text="property.to || '-'" />
                  </div>
                </div>
              </div>
              <p class="mb-0" v-text="history.time" />
            </template>
          </app-timeline-item>
        </app-timeline>
      </b-col>
    </b-row>
    <template #modal-footer="{}">
      <b-button variant="primary" @click="!editUuid ? createItem() : updateItem()">
        Simpan
      </b-button>
    </template>
  </b-modal>
</template>
<script>
/* eslint-disable vue/require-default-prop */
// import RiwayatToko from "@/components/Toko/Modal/RiwayatToko.vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import vSelect from "vue-select";
import { mapState } from "vuex";
import {
  BModal,
  BContainer,
  BRow,
  BCol,
  BButton,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
  BInputGroupAppend,
  BCard,
  BFormDatepicker,
} from "bootstrap-vue";

import moment from "moment";
import BaseInput from "@/components/BaseInput.vue";

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BButton,
    BContainer,
    BaseInput,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BCard,
    // RiwayatToko,
    AppTimeline,
    AppTimelineItem,
    BFormDatepicker,
    vSelect,
  },
  props: {
    formData: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: "",
    },
    historyData: {
      type: Array,
    },
    next_page_url: {
      type: "",
    },
    createItem: {
      type: Function,
    },
    editItem: {
      type: Function,
    },
    editId: {
      type: "",
    },
    updateItem: {
      type: Function,
    },
    cleanUpForm: {
      type: Function,
    },
    iconBank: {
      type: String,
    },
  },
  data() {
    return {
      moment,
      formPayload: {
        // name: '',
        // address: '',
        // pic_name: '',
        // pic_email: '',
        // pic_handphone: '',
        // email: '',
        // password: '',
        // password_confirmation: '',
        // expired_at: ''
      },
      historyDataList: [],
      next_page_url_data: "",
      editUuid: null,
      messages: [],
      optionStatus: [
        {
          name: "Gratis",
          value: "free",
        },
        {
          name: "Trial",
          value: "trial",
        },
        {
          name: "Berlangganan",
          value: "subscribe",
        },
      ],
      forms: [
        {
          class: "form-group",
          type: "text",
          name: "name",
          required: true,
          placeholder: "Nama Toko",
          label: "Nama Toko",
          classLabel: "",
          model: "name",
        },
        {
          class: "form-group",
          type: "text",
          name: "owner_name",
          disabled: true,
          placeholder: "Nama Owner",
          label: "Nama Owner",
          classLabel: "",
          model: "owner_name",
        },
        {
          class: "form-group",
          type: "text",
          name: "owner_phone",
          placeholder: "Nomor Kontak",
          label: "Nomor Kontak",
          classLabel: "",
          model: "owner_phone",
        },
        {
          class: "form-group",
          type: "text",
          name: "address",
          required: true,
          placeholder: "Alamat Toko",
          label: "Alamat Toko",
          classLabel: "",
          model: "address",
        },
        {
          class: "form-group",
          type: "text",
          name: "city",
          required: true,
          placeholder: "Kota",
          label: "Kota",
          classLabel: "",
          model: "city",
        },
        {
          class: "form-group",
          type: "text",
          name: "payment_method",
          disabled: true,
          placeholder: "Metode Pembayaran",
          label: "Metode Pembayaran",
          classLabel: "",
          model: "payment_method",
        },
        {
          class: "form-group",
          type: "text",
          name: "renewal_status",
          placeholder: "Status Akun",
          label: "Status Akun",
          classLabel: "",
          model: "renewal_status",
        },
        {
          class: "form-group",
          type: "text",
          name: "register_date",
          disabled: true,
          placeholder: "Tanggal Registrasi",
          label: "Tanggal Registrasi",
          classLabel: "",
          model: "register_date",
        },
        {
          class: "form-group",
          type: "text",
          name: "payment_date",
          disabled: true,
          placeholder: "Tanggal Pembayaran Xendit",
          label: "Tanggal Pembayaran Xendit",
          classLabel: "",
          model: "payment_date",
        },
        {
          class: "form-group",
          type: "text",
          name: "manual_payment_date",
          placeholder: "Tanggal Pembayaran Manual",
          label: "Tanggal Pembayaran Manual",
          classLabel: "",
          model: "manual_payment_date",
        },
        {
          class: "form-group",
          type: "text",
          name: "renewal_end_date",
          placeholder: "Tanggal Berakhir",
          label: "Tanggal Berakhir",
          classLabel: "",
          model: "renewal_end_date",
        },
        {
          class: "form-group",
          type: "text",
          name: "remaining_renewal_days",
          disabled: true,
          placeholder: "Sisa Waktu Berlangganan",
          label: "Sisa Waktu Berlangganan",
          classLabel: "",
          model: "remaining_renewal_days",
        },
      ],
      required: Boolean,
    };
  },
  computed: {
    ...mapState({
      payload: (state) => state.toko.formPayload,
    }),
  },
  mounted() {
    if (this.editId !== null) {
      this.required = false;
    } else {
      this.required = true;
    }
  },
  watch: {
    iconBank(value) {
      this.photo_url = value;
    },
    editId(value) {
      this.editUuid = value;
      if (value !== null) {
        this.required = false;
      } else {
        this.required = true;
      }
    },
    formData(value) {
      this.formPayload = value;
    },
    historyData(value) {
      this.historyDataList = value;
    },
    // next_page_url(value) {
    //   this.next_page_url_data = value;
    // },
    formPayload: {
      handler(value) {
        this.$emit("getPayload", value);
      },
      deep: true,
    },
  },
  methods: {
    getPhoto(value) {
      this.formPayload.logo = value.photo;
    },
    directPage() {
      this.cleanUpForm();
      this.$bvModal.hide("modal-toko");
    },
  },
};
</script>

<style lang="scss" scoped>
.box__upload--photo {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #eff1f5;
  border: 1px dashed #7a7f94;
  border-radius: 8px;
  position: relative;

  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;

  width: 195px;
  height: 140px;

  .floating__close--button {
    position: absolute;
    top: 6px;
    width: 24px;
    height: 24px;
    right: 6px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@/assets/scss/variables/_variables.scss";

.timeline-variant-success {
  .timeline-item-point {
    background-color: unset !important;

    &::before {
      background-color: unset !important;
      background: linear-gradient(180deg,
          #45b6ab 0%,
          rgba(69, 182, 171, 0.5) 100%);
      box-shadow: inset 1.67273px 1.67273px 3.34545px rgb(230 219 211 / 20%);
    }
  }
}
</style>
