<template>
  <div class="section">

    <TableToko :result="result" :delete-data="deleteToko" :get-data="getToko" :edit-item="editItem"
      @filterBank="getFilter" />

    <ModalToko :form-data="formPayload" :create-item="createItem" :edit-id="editId" :edit-item="editItem"
      :update-item="updateItem" :clean-up-form="cleanUpForm" :icon-bank="iconBank" :history-data="historyData"
      @getPayload="getPayload" />
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from "lodash";
import { mapActions, mapState } from "vuex";
import { VBToggle } from "bootstrap-vue";
import {
  checkPermission,
  successNotification,
  errorNotification,
} from "@/auth/utils";
import moment from "moment";

import HeaderPage from "@/components/HeaderPage.vue";
import ModalToko from "@/components/Toko/Modal/ModalToko.vue";
import TableToko from "@/components/Toko/TableToko.vue";

export default {
  title() {
    return "Toko";
  },
  components: {
    HeaderPage,
    TableToko,
    ModalToko,
  },
  computed: {
    ...mapState({
      merchants: (state) => state.toko.merchants,
      payload: (state) => state.toko.formPayload,
    }),
  },
  directives: {
    "b-toggle": VBToggle,
  },
  data() {
    return {
      currentPage: 1,
      isLoading: true,
      result: {},
      validations: [],
      editId: null,
      iconBank: null,
      formPayload: this.payload,
      historyData: [],
      filter: {
        search: "",
        register_date: "",
        per_page: 10,
        sort_type: "desc",
      },
    };
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    };
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getToko();
      }, 300),
      deep: true,
    },
  },
  created() {
    this.getToko();
  },
  methods: {
    ...mapActions("toko", ["getData", "postData", "deleteData", "showData"]),
    getPayload(value) {
      this.formPayload = value;
    },
    getFilter(value) {
      this.filter = value;
    },
    cleanUpForm() {
      this.editId = null;
      this.iconBank = null;
      this.formPayload = {
        name: "",
        owner: {
          name: "",
          phone: null,
        },
        users: [
          {
            name: "",
            phone: null,
            email: "",
            last_login: "",
          },
        ],
        sellers: [
          {
            name: "",
            phone_number: null,
          },
        ],
        address: "",
        city: "",
        payment_method: "",
        payment_date: "",
        register_date: "",
        renewal_end_date: "",
        renewal_status: "",
        remaining_renewal_days: "",
        manual_payment_date: "",
        newly_registered: "",
        historyData: [],
        pagePlus: 1,
        // pic_name: '',
        // pic_email: '',
        // pic_handphone: '',
        // email: '',
        // password: '',
        // password_confirmation: '',
      };
      this.validations = "";
    },
    editItem(item) {
      try {
        this.cleanUpForm();
        this.editId = item.id;
        this.iconBank = item.logo_url;

        this.$http
          .get(`/super-admin/merchant/${this.editId}/history`, {
            params: {
              page: this.pagePlus,
              per_page: 3,
            },
          })
          .then((response) => {
            this.historyData = response.data.data.data;
          });

        let users = [];
        if (this.formPayload && this.formPayload.users) {
          users = item?.users?.map((user) => ({
            name: user.name ? user.name || "-" : "-",
            phone: user.phone ? user.phone || "-" : "-",
            email: user.email ? user.email || "-" : "-",
            last_login: user.last_login ? user.last_login || "-" : "-",
          }));
        }

        let sellers = [];
        if (item.sellers) {
          sellers = item?.sellers?.map((seller) => ({
            name: seller.name ? seller.name : "-",
            phone_number: seller.phone_number ? seller.phone_number : "-",
          }));
        }

        this.$store.commit(
          "bank/SET_FORM_PAYLOAD",
          (this.formPayload = {
            name: item.name || "-",
            owner_name: item.owner ? item.owner.name : "-",
            owner_phone: item.owner ? item.owner.phone : "-",
            address: item.address,
            city: item.city,
            payment_method: item.xendit_payment_date ? "Xendit" : "Non Xendit",
            payment_date: item.xendit_payment_date || "-",
            register_date: item.register_date || "-",
            renewal_end_date: item.renewal_end_date || "-",
            renewal_status: item.renewal_status || "-",
            manual_payment_date: item.manual_payment_date,
            remaining_renewal_days: item.remaining_renewal_days + " Hari" || "-",
            newly_registered: item.newly_registered ? "Toko Baru" : "Toko Lama",
            users: users,
            sellers: sellers,
            // pic_name: item.pic_name,
            // pic_email: item.pic_email,
            // pic_handphone: item.pic_handphone,
            // email: item.email,
            // password: '',
            // password_confirmation: '',
            // expired_at: moment(item.expired_at).format('YYYY-MM-DD'),
          })
        );
        this.$bvModal.show("modal-toko");
      } catch (error) {
        console.log(error, 'error');
      }
    },
    async getToko(page = 1) {
      this.isLoading = true;
      const queryParams = this.filter;
      queryParams.page = page;
      await this.getData({
        params: queryParams,
      });
      this.result = this.merchants;
      this.currentPage = this.merchants.current_page;
      this.isLoading = false;
    },
    async createItem() {
      const form = this.preparePayload();
      this.isLoading = true;
      const response = await this.postData({
        id: "",
        payload: form,
      });
      if (response) {
        this.isLoading = false;
        this.$bvModal.hide("modal-toko");
        this.getToko();
        successNotification(this, "Success", "Toko berhasil di buat");
        this.cleanUpForm();
      }
    },
    async updateItem() {
      if (!this.formPayload.password) {
        delete this.formPayload.password;
        delete this.formPayload.password_confirmation;
      }
      const form = this.preparePayload();
      this.isLoading = true;
      form.append("_method", "PATCH");

      try {
        const response = await this.postData({
          id: this.editId,
          payload: form,
        });
        
        if (response) {
          this.getToko();
          successNotification(this, "Success", "Toko berhasil di ubah!");
          this.cleanUpForm();
          this.isLoading = false;
          this.$bvModal.hide("modal-toko");
        }
      } catch (error) {
        errorNotification(this, "Error", error?.response?.data?.meta?.messages?.join(' , '));
      }
    },
    async deleteToko(uuid) {
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure to delete this toko?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          const response = await this.deleteData({
            id: uuid,
          });
          this.getToko();
          this.$swal({
            icon: "success",
            title: "Success!",
            text: "Toko berhasil dihapus",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
    },
    preparePayload() {
      const form = new FormData();
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.formPayload) {
        // eslint-disable-next-line no-prototype-builtins
        if (
          this.formPayload.hasOwnProperty(key) &&
          this.formPayload[key] != null
        ) {
          if (key === "renewal_end_date" && "manual_payment_date") {
            // Format renewal_end_date to Y-m-d format
            const date = new Date(this.formPayload[key]);
            const formattedDate = `${date.getFullYear()}-${String(
              date.getMonth() + 1
            ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
            form.append(key, formattedDate);
          } else {
            form.append(key, this.formPayload[key]);
          }
        }
      }

      if (this.formPayload.users) {
        this.formPayload.users.forEach((user, index) => {
          // eslint-disable-next-line no-restricted-syntax
          for (const key in user) {
            if (user.hasOwnProperty(key) && user[key] != null && user[key] != '' && user[key] != '-') {
              form.append(`users[${index}][${key}]`, user[key])
            }
          }
        })
      }
      return form;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";

.px-33 {
  padding-left: 33px;
  padding-right: 33px;
}

.px-16 {
  padding-left: 16px;
  padding-right: 16px;
}

.py-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.py-18 {
  padding-top: 18px;
  padding-bottom: 18px;
}

#app {
  background: #f6f7fa;
}

.master-toko {
  height: 100vh;
}
</style>
